import type { PromotionTag } from '../../../../../types/laptop/index.ts'

/**
 * PromotionTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): PromotionTag.Props {
  const apb = data.value.aggregateProductBusiness || {}
  let info = null as any
  if (!info) info = apb.productTags?.find(item => item.type == 'payMemberGift' && !item.style)
  if (!info) info = apb.productTags?.find(item => item.type == 'promotion')
  const tagData = info && info.data?.[info.type]

  // 新人专享商品
  const isNewcomerItem = apb.showNewUsersBonus === '1'
  // 是否赠品
  const isSheinClubGiftItem = info?.type === 'payMemberGift'
  const isGiftItem = (apb.isPresent === '1' || isSheinClubGiftItem)
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  const isShowMask = (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem
  const isClick = isSheinClubGiftItem ? true : tagData?.canJump === '1'
  const timestamp = +tagData?.endTimeStamp

  let displayDateOrTime = false
  if (isSheinClubGiftItem && timestamp && isTimestampOlderThan72Hours(timestamp * 1000)) {
    displayDateOrTime = true
  }

  const type = getType(info)
  let textColor = info?.view?.textColor || ''
  let bgColor = info?.view?.bgColor || ''
  if (!textColor && !bgColor) {
    const { text, bg } = getColor(type)
    textColor = text
    bgColor = bg
  }

  return {
    isShow: !!info,
    isShowMask,
    isClick,
    text: info?.view.text || '',
    tip: tagData?.tips || '',
    timestamp,
    reloadOnTimeEnd: true,
    endInTip: tagData?.endInTip || '',
    displayDateOrTime,
    type: getType(info),
    data: info,
    textColor,
    bgColor,
    maxWidth: '100%',
  }
}

function isTimestampOlderThan72Hours(timestamp) {
  const THREE_DAYS_IN_MS = 72 * 60 * 60 * 1000
  const now = Date.now()
  const timeDifference = timestamp - now

  return timeDifference > THREE_DAYS_IN_MS
}

function getType(info) {
  if (!info) return ''
  if (info.type === 'payMemberGift') return 'club-gift'
  if (info.type === 'promotion') {
    const tagData = info && info.data?.[info.type]
    if (tagData.typeId === '10' && ['1', '4', '5'].includes(tagData.flashType)) return 'flash'
    if (tagData.typeId === '32') return 'random'
    return ''
  }
  return ''
}

function getColor(type) {
  switch (type) {
    case 'club-gift':
      return {
        text: '#873c00',
        bg: '#fff6f3',
      }
    case 'flash':
      return {
        text: '#a86104',
        bg: '#fdf4D0',
      }
    case 'coupon':
      return {
        text: '#fa6338',
        bg: '',
      }
    default:
      return {
        text: '#c44a01',
        bg: '#fff6f3',
      }
  }
}
