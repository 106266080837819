import RankListTag from './rank-list-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useRankListTag = createHelper('RankListTag', getProps)

export {
  RankListTag,
  useRankListTag,
}
