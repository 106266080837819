import type { GoodsAlert } from '../../../../types/laptop/index.ts'

/**
 * GoodsAlert 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): GoodsAlert.Props {
  // 新人专享价提示语
  const newUserTips = data.value.aggregateProductBusiness?.showNewUsersBonus == '1' ? language.value.SHEIN_KEY_PC_27125 : ''
  // 失去新人专享价资格而售罄的提示语
  const newUserStockOutTips = data.value.aggregateProductBusiness?.newUserStockOutTips || ''
  // 付费会员赠品售罄提示语
  const payMemberGiftStockOutTips = data.value.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'payMemberGift' && tag.style == '-1')?.view?.text || ''
  // 重新选择提示语
  const reselectTip = data.value.aggregateProductBusiness?.changeGoodInfo?.tip || ''
  // 可切 MALL 提示语
  const changeMallTip = data.value.aggregateProductBusiness?.changeMallInfo?.tip || ''
  // 百亿补贴仅app可下单提示语，不会与（专享价、切mall、付费会员赠品）提示语同时返回；与重新选择提示语相比，百补提示语优先级更高
  const brandDealsPurchaseTip = data.value.aggregateProductBusiness?.brandDealsPurchaseTip || ''

  return {
    isShow: !!(newUserTips || newUserStockOutTips || payMemberGiftStockOutTips || reselectTip || changeMallTip || brandDealsPurchaseTip),
    newUserTips,
    newUserStockOutTips,
    payMemberGiftStockOutTips,
    reselectTip,
    changeMallTip,
    brandDealsPurchaseTip,
  }
}
