<template>
  <div
    :class="classnames"
    :style="style"
  >
    <div class="bsc-cart-item-estimated-reference__text">
      {{ text }}
    </div>
    <ClientOnly>
      <template v-if="showCountdown">
        <div class="bsc-cart-item-estimated-reference__line"></div>
        <CartCountdown
          class="bsc-cart-item-estimated-reference__count-down"
          font-size="10px"
          :timestamp="timestamp"
          :font-color="textColor"
          :colon-color="textColor"
          font-bg-color="#fff"
        />
      </template>
    </ClientOnly>
    <sui_icon_more_down_12px_1
      v-if="isHover"
      size="12px"
      :class="[
        'bsc-cart-item-estimated-reference__icon',
        visible && 'bsc-cart-item-estimated-reference__icon-flip',
      ]"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import sui_icon_more_down_12px_1 from '../../components/icon/sui_icon_more_down_12px_1.vue'
import CartCountdown from '../../components/cart-countdown.vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否可点击
   * @type {Boolean}
   */
  isHover: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
  /**
   * 隐藏倒计时
   */
  hideCountdown: {
    type: Boolean,
    default: false,
  },
  timestamp: {
    type: Number,
    default: null,
  },
  textColor: {
    type: String,
    default: '#FA6338',
  },
  lineColor: {
    type: String,
    default: '#FDB9A6',
  },
  bgColor: {
    type: String,
    default: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const classnames = computed(() => [
  'bsc-cart-item-estimated-reference',
  {
    'bsc-cart-item-estimated-reference__pointer': props.isHover,
  },
])

const style = computed(() => ({
  '--text-color': props.textColor,
  '--line-color': props.lineColor,
  '--bg-color': props.bgColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const showCountdown = computed(() => {
  if (props.hideCountdown || !props.timestamp) return false

  return true
})

</script>

<style lang="less">
.bsc-cart-item-estimated-reference {
  display: inline-flex;
  align-items: center;
  padding: 2px 4px;
  height: 18px;
  background: linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%);
  background: var(--bg-color);
  opacity: var(--mask-opacity);
  vertical-align: bottom;

  &__pointer {
    cursor: pointer;
  }

  &__text {
    flex-shrink: 1;
    font-size: 10px;
    color: #FA6338;
    color: var(--text-color);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    & + .bsc-cart-item-estimated-reference__discount {
      margin-left: 2px;
    }
  }

  &__discount {
    flex-shrink: 0;
    font-size: 10px;
    font-weight: 500;
    color: #FA6338;
    color: var(--text-color);
    direction: ltr/* rtl:ignore */;
  }

  &__line {
    flex-shrink: 0;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid #FDB9A6;
    border-left-color: #FDB9A6;
    border-left-color: var(--line-color);
  }

  &__count-down {
    display: flex;
    flex-shrink: 0;
  }

  &__icon {
    color: #FA6338;
    color: var(--text-color);

  }

  &__icon-flip {
    transform: rotateX(180deg);
  }
}
</style>
