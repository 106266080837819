import type { BrandDealsTag } from '../../../../../types/laptop/index.ts'

export function getProps({ data }): BrandDealsTag.Props {
  // 是否失效商品
  const isInvalidGoods = data.value.isInvalid == '1'
  const info = data.value.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'brandDeals') || null
  const { url = '', width = '', height = '' } = info?.view?.imageInfo || {}
  return {
    isShow: !!info,
    isShowMask: isInvalidGoods,
    url,
    width,
    height,
  }
}
