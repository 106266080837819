<template>
  <div
    v-if="isShow"
    :class="classnames"
    :style="style"
  >
    <div class="bsc-cart-item-insure-price-tag__text">
      {{ text }}
    </div>
    <SPopover
      v-if="tip"
      trigger="hover"
      fix-disappear-position
      append-to-body
      :delay-render="300"
      :content="tip"
      :prop-style="{width: '266px', boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)', padding: '10px', lineHeight: '18px', whiteSpace: 'normal'}"
    >
      <template #reference>
        <sui_icon_info_12px_1
          size="12px"
          color="#959595"
          class="bsc-cart-item-insure-price-tag__icon-tip"
        />
      </template>
    </SPopover>
    <ClientOnly>
      <template v-if="showCountdown">
        <div class="bsc-cart-item-insure-price-tag__line"></div>
        <CartCountdown
          v-if="timestamp"
          class="bsc-cart-item-insure-price-tag__count-down"
          v-bind="cartCountDownProps"
        />
      </template>
    </ClientOnly>
  </div>
</template>

<script name="PromotionTag" setup lang="ts">
import { computed, watch } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import sui_icon_info_12px_1 from '../../../components/icon/sui_icon_info_12px_1.vue'
import { hexToRgb } from '@shein/common-function'
import CartCountdown from '../../../components/cart-countdown.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'
import type { InsurePriceTag } from '../../../../../types/laptop/index.ts'
import { SPopover } from '@shein-aidc/sui-popover/laptop'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['expose', 'timeEnd'])

const props = withDefaults(defineProps<InsurePriceTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  tip: '',
  timestamp: 0,
  reloadOnTimeEnd: true,
  data: null,
  textColor: '',
  bgColor: '',
  maxWidth: '100%',
})

const classnames = computed(() => {
  const cls = [
    'bsc-cart-item-insure-price-tag',
    {
      'has-count-down': showCountdown.value,
    },
  ]
  return cls
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--text-color': props.textColor,
  '--bg-color': props.bgColor,
  '--rgba-bg-color': hexToRgb({ hex: props.bgColor, opa: 0.5 }),
  '--rgba-line-color': hexToRgb({ hex: props.textColor, opa: 0.4 }),
  maxWidth: props.maxWidth,
}))


const showCountdown = computed(() => !!props.timestamp)

const cartCountDownProps = computed(() => ({
  timestamp: +props.timestamp,
  hideOnZero: true,
  reloadOnZero: props.reloadOnTimeEnd,
  fontColor: '#222',
  colonColor: '#222',
  fontBgColor: '#fff',
}))

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose', props.data)
      }
      registerExpose('InsurePriceTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-insure-price-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  vertical-align: middle;
  opacity: var(--mask-opacity);
  background: var(--bg-color);

  &.has-count-down {
    background: linear-gradient(90deg, var(--bg-color) 0%, var(--rgba-bg-color) 100%);
  }

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--text-color);
  }

  &__icon-tip {
    margin-left: 4px;
    line-height: 1 !important; /* stylelint-disable-line declaration-no-important */
  }

  &__line {
    flex-shrink: 0;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid @sui_color_gray_dark3;
  }

  &__count-down {
    flex-shrink: 0;
  }

  &__date {
    flex-shrink: 0;
    color: var(--text-color);
  }

  &__pointer {
    cursor: pointer;
  }
}
</style>
