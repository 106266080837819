import BrandDealsTag from './brand-deals-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useBrandDealsTag = createHelper('BrandDealsTag', getProps)

export {
  BrandDealsTag,
  useBrandDealsTag,
}
