import BundleBuyTag from './bundle-buy-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useBundleBuyTag = createHelper('BundleBuyTag', getProps)

export {
  BundleBuyTag,
  useBundleBuyTag,
}
