<template>
  <div
    :style="styles"
    class="bsc-cart-item-goods-price-v2"
  >
    <!-- 价格顶部区域 -->
    <div class="bsc-cart-item-goods-price-v2__top">
      <!-- 顶部左侧 -->
      <!-- 放价格 -->
      <div :class="leftClasses">
        <div class="bsc-cart-item-goods-price-v2__left-before">
          <!-- 营销图标 -->
          <PromotionIcon
            v-if="isShowPromotionIcon"
            class="bsc-cart-item-goods-price-v2__promotion-icon bsc-cart-item-goods-price-v2_mask"
            :is-show-premium-member-icon="isShowPremiumMemberIcon"
            :is-show-time-icon="isShowTimeIcon"
            :is-show-app-only-icon="isShowAppOnlyIcon"
            :is-show-brand-sale-icon="isShowBrandSaleIcon"
            :is-show-flash-sale-icon="isShowFlashSaleIcon"
            :is-show-brand-deals-icon="!!brandDealsIcon"
            :premium-member-icon-color="premiumMemberIconColor"
            :time-icon-color="timeIconColor"
            :app-only-icon-color="appOnlyIconColor"
            :brand-sale-icon-color="brandSaleIconColor"
            :flash-sale-icon-color="flashSaleIconColor"
            :brand-deals-icon-src="brandDealsIcon"
          />
          <SheinVipIcon
            v-if="isShowSheinVipIcon"
            class="bsc-cart-item-goods-price-v2__shein-vip-icon bsc-cart-item-goods-price-v2_mask"
          />
          <span
            class="bsc-cart-item-goods-price-v2__sale-price bsc-cart-item-goods-price-v2_mask"
            v-html="salePrice"
          ></span>
          <span :class="otherPriceClasses">
            <span
              v-if="isShowOriginalPrice"
              class="bsc-cart-item-goods-price-v2__original-price bsc-cart-item-goods-price-v2_mask"
            >
              <del>{{ originalPrice }}</del>
              <span v-if="isShowOriginalPriceText">&nbsp;{{ originPriceText }}</span>
              <span
                v-if="isShowOriginalPricePopover"
                class="bsc-cart-item-goods-price-v2__original-price-popover"
              >
                <SPopover
                  trigger="hover"
                  theme="dark"
                  :show-close-icon="true"
                  :outside-close="false"
                  :content="originPricePopoverText"
                >
                  <template #reference>
                    <sui_icon_info_12px_1 />
                  </template>
                </SPopover>
              </span>
            </span>
          </span>
        </div>
        <div class="bsc-cart-item-goods-price-v2__left-after">
          <!-- 由插入的组件自己控制 mask -->
          <slot
            v-if="discountTagPosition === 'original-price-after'"
            name="discount-tag">
          </slot>
          <slot name="price-after"></slot>
          <!-- 由插入的组件自己控制 mask -->
        </div>
      </div>
      <!-- 顶部右侧 -->
      <!-- 放商品操作：比如数量变更 -->
      <div class="bsc-cart-item-goods-price-v2__right">
        <slot name="top-right"></slot>
      </div>
    </div>
    <!-- 价格底部区域 -->
    <!-- 放 30 天最低价提示文案 / RRP -->
    <div class="bsc-cart-item-goods-price-v2__bottom">
      <span
        v-if="isShowSuggestPrice"
        class="bsc-cart-item-goods-price-v2__suggest-price"
      >
        <del>{{ suggestPrice }}</del>
        <span v-if="isShowSuggestPriceText">&nbsp;{{ suggestPriceText }}</span>
      </span>
      <slot
        v-if="discountTagPosition === 'suggest-price-after'"
        name="discount-tag">
      </slot>
    </div>
  </div>
</template>

<script name="GoodsPriceV2" setup lang="ts">
import { computed } from 'vue'
import sui_icon_info_12px_1 from '../../components/icon/sui_icon_info_12px_1.vue'
import { SPopover } from '@shein-aidc/sui-popover/laptop'
import PromotionIcon from './components/promotion-icon.vue'
import SheinVipIcon from './components/shein-vip-icon.vue'
import type { GoodsPrice } from '../../../../types/laptop/index.ts'

/**
 * 商品行价格组件V2
 */

// props
const props = withDefaults(defineProps<GoodsPrice.Props>(), {
  salePrice: '',
  salePriceColor: '#000',
  isShowMask: false,
  isShowOriginalPrice: false,
  isShowOriginalPriceText: false,
  isShowOriginalPricePopover: false,
  originalPrice: '',
  originPriceText: '',
  originPriceColor: '',
  originPriceCrossed: false,
  originPricePopoverText: '',
  isShowSuggestPrice: false,
  isShowSuggestPriceText: false,
  suggestPrice: '',
  suggestPriceText: '',
  suggestPriceColor: '',
  suggestPriceCrossed: false,
  isShowSheinVipIcon: false,
  isShowPromotionIcon: false,
  isShowPremiumMemberIcon: false,
  isShowTimeIcon: false,
  isShowAppOnlyIcon: false,
  isShowBrandSaleIcon: false,
  isShowFlashSaleIcon: false,
  premiumMemberIconColor: '',
  timeIconColor: '',
  appOnlyIconColor: '',
  brandSaleIconColor: '',
  flashSaleIconColor: '',
  discountTagPosition: 'original-price-after',
  brandDealsIcon: '',
})

// data
// computed
const styles = computed(() => ({
  '--sale-price-color': props.salePriceColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--original-price-color': props.originPriceColor,
  '--original-price-crossed': props.originPriceCrossed ? 'line-through' : 'none',
  '--suggest-price-color': props.suggestPriceColor,
  '--suggest-price-crossed': props.suggestPriceCrossed ? 'line-through' : 'none',
}))

const leftClasses = computed(() => [
  'bsc-cart-item-goods-price-v2__left',
  props.isAfterWrap ? 'bsc-cart-item-goods-price-v2_after-wrap' : 'bsc-cart-item-goods-price-v2_after-no-wrap',
])

const otherPriceClasses = computed(() => ({
  'bsc-cart-item-goods-price-v2__other-price': true,
  // 不展示气泡时提示图标时整体文案进行溢出隐藏
  'bsc-cart-item-goods-price-v2__other-price_overflow': !props.isShowOriginalPricePopover,
}))

</script>

<style lang="less">
@height: 18px;

.bsc-cart-item-goods-price-v2 {
  line-height: 1;

  &_after-wrap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 2px;
    .bsc-cart-item-goods-price-v2__left-before {
      flex-shrink: 0;
    }
    .bsc-cart-item-goods-price-v2__left-after {
      flex-shrink: 1;
    }
  }

  &_after-no-wrap {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    height: @height;
    .bsc-cart-item-goods-price-v2__left-before {
      flex: 1;
    }
  }

  &__top {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__left {
    min-width: 0;
  }
  &__left-before {
    display: inline-flex;
    align-items: baseline;
    flex: 1;
    width: 100%;
    flex-wrap: nowrap;
    &.bsc-cart-item-goods-price-v2__hide-other-price {
      flex-wrap: wrap;
      height: @height;
      line-height: @height;
      overflow: hidden;
      flex: auto;
      width: initial;
      max-width: 100%;

      .bsc-cart-item-goods-price-v2__other-price {
        flex: none;
      }
      .bsc-cart-item-goods-price-v2__promo-price {
        flex: 0;
      }
    }
  }
  &__left-after {
    min-width: 0;
  }
  &_mask {
    opacity: var(--mask-opacity);
  }
  &__right {
    flex-shrink: 0;
    margin-left: 4px;
  }
  &__bottom {
    opacity: var(--mask-opacity);
  }
  &__promotion-icon {
    align-self: center;
    margin-right: 2px;
    font-size: 0;
    flex-shrink: 0;
  }

  &__shein-vip-icon {
    align-self: baseline;
    flex-shrink: 0;
  }

  &__brand-deals-icon {
    width: 16px;
    height: 16px;
    align-self: center;
    flex-shrink: 0;
    opacity: var(--mask-opacity);
  }

  &__sale-price {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: var(--sale-price-color);
    direction: ltr;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  &__other-price {
    display: inline-flex;
    overflow: hidden;
    flex: 1;

    &:not(.bsc-cart-item-goods-price-v2__other-price_overflow) {
      .bsc-cart-item-goods-price-v2__original-price {
        width: fit-content;
        overflow: hidden;
      }

      .bsc-cart-item-goods-price-v2__original-price del {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_overflow {
      display: inline;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .bsc-cart-item-goods-price-v2__original-price {
        display: inline;
      }
    }
  }

  &__original-price {
    display: inline-flex;
    margin-left: 2px;
    font-size: 10px;
    line-height: 12px;
    color: var(--original-price-color, #767676);
    del {
      text-decoration: var(--original-price-crossed);
    }
  }

  &__original-price-popover {
    margin-left: 2px;
    width: 14px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    .sui-popover__trigger {
      font-size: 0;
    }
  }

  .bsc-cart-item-goods-price-v2__suggest-price {
    display: inline;
    font-size: 10px;
    color: var(--suggest-price-color, #767676);
    del {
      text-decoration: var(--suggest-price-crossed);
    }
  }
}
</style>
