import type { BundleBuyTag } from '../../../../../types/laptop/index.ts'

/**
 * BundleBuyTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): BundleBuyTag.Props {
  const info = data.value?.aggregateProductBusiness?.productTags?.find((item) => item.type === 'bundleBuy')

  return {
    isShow: !!info,
    isShowMask: false,
    text: info?.view?.text || '',
    bgColor: info?.view?.bgColor || '',
    textColor: info?.view?.textColor || '',
    maxWidth: '100%',
  }
}
