import InsurePriceTag from './insure-price-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useInsurePriceTag = createHelper('InsurePriceTag', getProps)

export {
  InsurePriceTag,
  useInsurePriceTag,
}
